import React, { useState, useContext } from 'react';
import { partial } from 'ramda';
import { Transition } from 'react-spring';
import { ReactComponent as MagicWand } from '../logos/magic_wand.svg';
import { colorThemes } from '../utils/colorThemes';
import { cards } from '../utils/cards';
import { ContextOne } from '../context/ContextOne';
import AboutMe from '../components/AboutMe';
import Skills from '../components/Skills';
import Header from '../components/Header';
import Particles from '../components/Particles';
import Card from '../components/Card';


const newComponents = (type, theme, toggleFunction) => {
  if (type === 'about-me') {
    return <AboutMe theme={theme} toggleBack={toggleFunction} />
  } else if (type === 'projects') {
    return <div>projects</div>
  } else {
    return <Skills headerColor={theme.headerColor} toggleBack={toggleFunction} />
  }
};

const LandingContainer = ({ styles }) => {
  let { state, dispatch } = React.useContext(ContextOne);
  const [toggle, setToggle] = useState(false);
  const [page, setPage] =useState(null);

  let setColor = (color) => dispatch({
      type: "set-theme",
      payload: color
  });

  const getRandomTheme = () => {
    let currentTheme = state.theme;
    let themesWithoutCurrentTheme = colorThemes.filter(theme => theme.backgroundColor !== currentTheme.backgroundColor);
    return themesWithoutCurrentTheme[Math.floor(Math.random() * themesWithoutCurrentTheme.length)];
  };

  const openToggle = (index) => {
    if (index === 1) {
      return;
    } else if ( index === 2) {
      setToggle(true);
      setPage('skills')
    } else {
      setToggle(true);
      setPage('about-me')
    }
  };

  const {
    backgroundColor,
    particleColor,
    cardBackground,
    cardTextColor,
    headerColor
  } = state.theme;
  return (
    <>
      <div style={{backgroundColor: backgroundColor, height: '100%', padding: '20px' }}>
        <div className="main">
          <Particles activeTheme={state.theme}>
            <MagicWand
              onClick={partial(setColor, [getRandomTheme()])}
              className="magicWand__logo"
            />
            <div className="container">
              <Header headerColor={headerColor} />
            </div>
            <div className="another_container">
              <div className="row">
                <Transition
                  items={toggle}
                  from={{
                    opacity: 0,
                    transform: 'scale3d(0.5,0.5,0.5)'
                  }}
                  enter={{
                    opacity: 1,
                    transform: 'scale3d(1,1,1)'
                  }}
                  leave={{
                    opacity: 0,
                    transform: 'scale3d(0.5,0.5,0.5)'
                  }}
                >
                {toggle =>
                  toggle
                    ? props =>
                      <div style={props} className="otherContent">
                        {newComponents(page, state.theme, setToggle)}
                      </div>
                    : props => { return cards.map((card, i) => (
                      <div className="column" key={i} style={props}>
                        <Card cardBackground={cardBackground}>
                          <div className="card-title" style={{color: cardTextColor}}>
                            {card.title}
                          </div>
                          <div
                            className="card-body"
                            style={{color: cardTextColor}}
                            onClick={partial(openToggle, [i])}
                          >
                            {card.description}
                            <p>{card.newLine}</p>
                          </div>
                        </Card>
                      </div>
                    ))}
                  }
                </Transition>
              </div>
            </div>
          </Particles>
        </div>
      </div>
    </>
  )
};

export default LandingContainer;

// without functional
// let setColor = color => () => dispatch x
