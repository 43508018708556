export const colorThemes = [
  {
    backgroundColor: '#61a8d9',
    particleColor: '#5249af',
    cardBackground: '#5249af',
    cardTextColor: '#1b1430',
    headerColor: '#1b1430'
  },
  {
    backgroundColor: '#e26b80',
    particleColor: '#f6b21d',
    cardBackground: '#6979ba',
    cardTextColor: '#fbfbfb',
    headerColor: '#fbfbfb'
  },
  {
    backgroundColor: '#b8b8ff',
    particleColor: '#53225c',
    cardBackground: '#ff5ec4',
    cardTextColor: '#53225c',
    headerColor: '#53225c'
  },
  {
    backgroundColor: '#f36536',
    particleColor: '#f8c27e',
    cardBackground: '#2c353a',
    cardTextColor: '#f36536',
    headerColor: '#f8c27e'
  },
  {
    backgroundColor: '#3d666d',
    particleColor: '#f3f7fb',
    cardBackground: '#97b4b5',
    cardTextColor: '#3d666d',
    headerColor: '#f3f7fb'
  },
  {
    backgroundColor: '#a24b40',
    particleColor: '#f8f5f3',
    cardBackground: '#d9a563',
    cardTextColor: '#f8f5f3',
    headerColor: '#f8f5f3'
  },
  {
    backgroundColor: '#fbfbfb',
    particleColor: '#e26b80',
    cardBackground: '#6979ba',
    cardTextColor: '#fbfbfb',
    headerColor: '#e26b80'
  },
  {
    backgroundColor: '#e1ad2b',
    particleColor: '#b05c65',
    cardBackground: '#eaf4fb',
    cardTextColor: '#0a4d7b',
    headerColor: '#0a4d7b'
  },
  {
    backgroundColor: '#fafaf6',
    particleColor: '#6acab2',
    cardBackground: '#9565b6',
    cardTextColor: '#fafaf6',
    headerColor: '#6acab2'
  }
];
