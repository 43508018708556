import React, { useState, useRef, useEffect } from 'react';
import CountUp from 'react-countup';
import HorizontalTimeline from 'react-horizontal-timeline';
import { ReactComponent as BackArrow } from '../logos/back_arrow.svg';
import {
  usePrevious,
  useIsMobile
} from '../hooks';

const VALUES = ['2016/01/01', '2017/01/01', '2017/06/01', '2018/01/01', '2018/03/01'];

const someParagraphFunction = (value, themeColor) => {
  if (value === 0) {
    return (
      <div className='aboutMe__description--header' style={{ color: themeColor}}>
        <h1>Worked at Uber</h1>
        <h3>Role: Community Support Representative</h3>
        <h3>Solved riders and passengers problems via email.</h3>
      </div>
    )
  } else if (value === 1) {
    return (
      <div className='aboutMe__description--header' style={{ color: themeColor}}>
        <h1>Went backpacking around South America for a year.</h1>
        <div className="empanadas">
          <CountUp start={0} end={127} duration={2.75} />
          <span>Empanadas eaten 🥟</span>
        </div>
        <h3>Loved every minute of it! Personal favourite was the Patagonia region.</h3>
      </div>
    )
  } else if (value === 2) {
    return (
      <div className='aboutMe__description--header' style={{ color: themeColor}}>
        <h1>Started 6-month bootcamp at Coder Academy Sydney</h1>
        <h3 className="aboutMe__description__text">
          Learnt everything Javascript focusing on React and Node.js
        </h3>
      </div>
    )
  } else if (value === 3) {
    return (
      <div className='aboutMe__description--header' style={{ color: themeColor}}>
        <h1>Software Internship at Mentally Friendly</h1>
        <h3 className="aboutMe__description__text">
          Worked on an application helping small businesses owners. Tool was built with Vue.js
        </h3>
      </div>
    )
  } else {
    return (
      <div className='aboutMe__description--header' style={{ color: themeColor}}>
        <h1>Developer at Station Five</h1>
        <h3 className="aboutMe__description__text">
          Working as a developer primarily using React, Redux and Node.js
        </h3>
      </div>
    )
  }
};

const AboutMe = ({ theme, toggleBack }) => {
  const [ value, setValue ] = useState(0);
  const prevCount = usePrevious(value);
  const isMobile = useIsMobile();
  return (
    <div>
      <BackArrow style={{ display: 'flex' }} onClick={() => toggleBack(false)} fill={theme.cardBackground} />
      <div className="aboutMe">
        <div className="aboutMe__timeline" style={{  color: theme.headerColor }}>
          <HorizontalTimeline
            index={value}
            isTouchEnabled={true}
            indexClick={(index) => {
              setValue(index);
            }}
            styles={{
              background: theme.backgroundColor,
              foreground: theme.cardBackground,
              outline: theme.cardBackground
            }}
            values={ VALUES } />
        </div>
        <div className='aboutMe__description'>
          {someParagraphFunction(value, theme.headerColor)}
        </div>
      </div>
    </div>
  )
};

export default AboutMe;
