export const cards = [
  {
    title: 'About Me',
    description: "I'm a software developer from Sydney and I like everything with Javascript" +
      "...I even put it on my toast 🍞",
    newLine: "I've always been fascinated in making (and breaking) things" +
      " and I believe the best products are built in collaboration."
  },
  {
    title: 'Projects️',
    description: "I've worked with a variety of organizations - from friends and small start-ups to" +
    " established businesses.",
    newLine: "Every project I've done, I try to learn and grow as much as possible. It's just more fun that way. 😀"
  },
  {
    title: 'What\'s in my Toolbelt?️',
    description: "I love working with people to understand the problem and help implement the solution" +
    "",
    newLine: "That being said I've also got a few technical tricks up my sleeve to get your beautiful ideas up and running. 🏃"
  }
];
