import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LandingContainer from './containers/LandingContainer';
import ResumeContainer from './containers/ResumeContainer';
import { ContextOneProvider } from './context/ContextOne';
import './App.scss';

const App = () =>  {
  return (
    <Router>
      <ContextOneProvider>
        <div>
          <Route exact path='/' component={LandingContainer} />
          <Route exact path='/resume' component={ResumeContainer} />
        </div>
      </ContextOneProvider>
    </Router>
  )
};

export default App;
