import React, { createContext, useReducer } from "react";

// creating the context
let ContextOne = createContext();

let initialState =  {
  theme: {
    backgroundColor: '#fbfbfb',
    particleColor: '#e26b80',
    cardBackground: '#6979ba',
    cardTextColor: '#fbfbfb',
    headerColor: '#e26b80'
  },
  reggie: 'beth loggins'
};

let reducer = (state, action) => {
  switch (action.type) {
    case "set-theme":
      return {
        theme: action.payload
      };
    default:
      return initialState;
  }
};

function ContextOneProvider(props) {
  let [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };

  return (
    <ContextOne.Provider value={value}>
      {props.children}
    </ContextOne.Provider>
  )
}

let ContextOneConsumer = ContextOne.Consumer;

export { ContextOne, ContextOneProvider, ContextOneConsumer }

