import React, { useState, useEffect, useContext }  from 'react';
import { Transition } from 'react-spring';
import { ContextOne } from '../context/ContextOne';
import { useIsMobile } from '../hooks';
import { ReactComponent as ReactLogoComp } from '../logos/react.svg';
import { ReactComponent as VueLogoComp } from '../logos/vue.svg';
import { ReactComponent as GraphQLLogo } from '../logos/graphql.svg';
import { ReactComponent as ReduxLogo } from '../logos/redux.svg';
import { ReactComponent as JavascriptLogo } from '../logos/javascript.svg';
import { ReactComponent as MongoDBLogo } from '../logos/mongodb.svg';
import { ReactComponent as NodeJSLogo } from '../logos/nodejs-icon.svg';
import { ReactComponent as SassLogo } from '../logos/sass-1.svg';
import { ReactComponent as PostGresqlLogo } from '../logos/postgresql.svg'

const items = [
  {
    id: 1,
    value: <JavascriptLogo />
  },
  {
    id: 2,
    value: <ReactLogoComp />
  },
  {
    id: 3,
    value: <ReduxLogo />
  },
  {
    id: 4,
    value: <GraphQLLogo />
  },
  {
    id: 5,
    value: <VueLogoComp />
  },
  {
    id: 6,
    value: <SassLogo />
  },
  {
    id: 7,
    value: <NodeJSLogo />
  },
  {
    id: 8,
    value: <MongoDBLogo />
  },
  {
    id: 9,
    value: <PostGresqlLogo />
  }
];

const mobileItems = [
  {
    id: 1,
    value: <JavascriptLogo height='100px' width='100px' />
  },
  {
    id: 2,
    value: <ReactLogoComp height='100px' width='100px' />
  },
  {
    id: 3,
    value: <ReduxLogo height='100px' width='100px'/>
  },
  {
    id: 4,
    value: <GraphQLLogo height='100px' width='100px' />
  },
  {
    id: 5,
    value: <VueLogoComp height='100px' width='100px' />
  },
  {
    id: 6,
    value: <SassLogo height='100px' width='100px' />
  },
  {
    id: 7,
    value: <NodeJSLogo height='100px' width='100px' />
  },
  {
    id: 8,
    value: <MongoDBLogo height='100px' width='100px' />
  },
  {
    id: 9,
    value: <PostGresqlLogo height='100px' width='100px' />
  }
];


const Skills = ({ headerColor, toggleBack }) => {
  const isMobile = useIsMobile();
  console.log(isMobile);
  useEffect(() => {
    if (isMobile) {
     return window.scrollTo(0,0)
    }
    return;
  }, []);
  return (
    <>
      <p
        className="skillsHeader"
        style={{ color: headerColor }}
      >
        My Toolbelt
      </p>
      <div className='toolbelt' onClick={() => toggleBack(false)}>
        <Transition
          items={isMobile ? mobileItems : items}
          keys={item => item.id}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          trail={150}
        >
          {item => props => (
            <div style={props}>
              <div className='toolbelt__logo'>
                {item.value}
              </div>
            </div>
          )}
        </Transition>
      </div>
    </>
  )
};

export default Skills;




