/** @jsx jsx */

import React from 'react';
import styled from '@emotion/styled';
import { Spring } from 'react-spring';
import { jsx, css, keyframes } from '@emotion/core'
import { ReactComponent as GithubLogo } from '../logos/github.svg';
import { ReactComponent as LinkedInLogo } from '../logos/linkedin.svg';
import { ReactComponent as TwitterLogo } from '../logos/twitter_bird.svg';

const HeaderContainer = styled.div`
  text-align: center;
  color: ${props => props.headerColor};
  font-family: 'Montserrat', sans-serif;
`;

const HeaderTitle = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  line-height: 4rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.1rem;
  margin-top: 0;
  font-family: 'Cabin', sans-serif;
`;

const HeaderDescription = styled.div`
  font-size: 1.375rem;
  margin: 1rem 0;
  font-weight: 300;
`;




const Header = ({ headerColor }) => {
  return (
    <HeaderContainer headerColor={headerColor}>
      <HeaderTitle>
        Hey there, I'm Terry!
      </HeaderTitle>
      <HeaderDescription>
        Developer | Coffee-Addict | Beer-Lover | Human
      </HeaderDescription>
      <div className="logo__container">
        <a href='https://github.com/terencejeong' target='_blank'>
          <GithubLogo fill={headerColor} className="logo__github" />
        </a>
        <a href='https://www.linkedin.com/in/terence-jeong' target='_blank'>
          <LinkedInLogo fill={headerColor} className="logo__linkedIn" />
        </a>
        <a href='https://twitter.com/terence_jeong' target='_blank'>
          <TwitterLogo fill={headerColor} className="logo__twitter" />
        </a>
      </div>
    </HeaderContainer>
  )
};

export default Header;
